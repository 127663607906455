import type { FC } from "react";
import { Suspense, lazy, useCallback } from "react";

import { useLocation, useParams } from "@remix-run/react";

import DetailBar from "./DetailBar";
import MainBar from "./MainBar";

import { useLocale, useRegion } from "~/libs/i18n";

export const styleProps = { display: { base: "flex", lg: "none" } };

const EventBar = lazy(() => import("./EventBar"));
const LatestEventBar = lazy(() => import("./LatestEventBar"));
const PostBar = lazy(() => import("./PostBar"));
const LocationBar = lazy(() => import("./LocationBar"));
const EditorChoiceBar = lazy(() => import("./EditorChoiceBar"));

const AppBar: FC = () => {
  const location = useLocation();
  const locale = useLocale();
  const region = useRegion();
  const { id } = useParams();

  const Component = useCallback(() => {
    if (id) return <DetailBar />;
    switch (location?.pathname) {
      case `/${region}/${locale}/event`:
        return <EventBar />;
      case `/${region}/${locale}/latest-event`:
        return <LatestEventBar />;
      case `/${region}/${locale}/editor-choice`:
        return <EditorChoiceBar />;
      case `/${region}/${locale}/post`:
        return <PostBar />;
      case `/${region}/${locale}/location`:
        return <LocationBar />;
      default:
        return <MainBar />;
    }
  }, [id, locale, location?.pathname, region]);

  return (
    <Suspense>
      <Component />
    </Suspense>
  );
};

export default AppBar;
