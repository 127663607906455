import { $, FallbackLocaleInputType } from "~/zeus";
import { typedGql } from "~/zeus/typedDocumentNode";

const TagsQuery = typedGql("query")({
    Tags: [
        {
            locale: $("locale", "LocaleInputType"),
            fallbackLocale: FallbackLocaleInputType.zh,
            where: $("where", "Tag_where"),
            limit: $("limit", "Int"),
        },
        {
            docs: {
                id: true,
                name: true
                
            }
        },
    ],
});

export default TagsQuery;
