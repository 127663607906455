import {
  Box,
  UseDisclosureReturn,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Flex,
  Image,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FC } from "react";
import { useTranslation } from "~/libs/i18n";
import CustomImage from "~/components/CustomImage";

const DownloadAppModal: FC<{
  downloadDisclosure: UseDisclosureReturn;
}> = ({ downloadDisclosure }) => {
  const t = useTranslation();
  return (
    <Modal
      isOpen={downloadDisclosure.isOpen}
      onClose={downloadDisclosure?.onClose}
      autoFocus={false}
      size={{ base: "full", md: "lg", lg: "4xl" }}
    >
      <ModalOverlay />
      <ModalContent
        w="full"
        borderRadius={"lg"}
        overflow={"hidden"}
        bgColor={"transparent"}
      >
        <ModalCloseButton top={[6, 6, 2]} right={[6, 6, 2]} />
        <ModalBody
          bgColor={"transparent"}
          overflow={"hidden"}
          borderRadius={"lg"}
          p={[4, 4, 0]}
        >
          <Box
            backgroundSize={"cover"}
            backgroundRepeat={"no-repeat"}
            backgroundImage="url('/assets/images/appDownload/appDownloadBg.svg')"
            backgroundPosition={"center bottom"}
            bgColor={"transparent"}
            borderRadius={"lg"}
            overflow={"hidden"}
            p={[4]}
            py={{ lg: 12 }}
          >
            <Flex>
              <Spacer flex={1} display={["none", "flex"]} />
              <Flex flex={1} direction={"column"} gap={[4, 4, 6]}>
                <Flex
                  flex={1}
                  direction={"row"}
                  gap={[4, 4]}
                  alignItems={"center"}
                >
                  <CustomImage src={"/assets/images/appDownload/appIcon.svg"} />
                  <Box fontSize={{ lg: "2xl" }} fontWeight={700}>
                    <Text>Timable App</Text>
                    <Text>隨時隨地搜尋城中活動</Text>
                  </Box>
                </Flex>

                <Text
                  fontSize={{ lg: "2xl" }}
                  textAlign={"center"}
                  fontWeight={700}
                >
                  掃一掃QR code下載App
                </Text>

                <Flex direction={"row"} gap={[4]} justifyContent={"center"}>
                  <VStack>
                    <CustomImage
                      src={"/assets/images/appDownload/Timable_iOS.png"}
                      alt="qrCode"
                    />
                    <CustomImage
                      src={"/assets/images/appDownload/appStore.svg"}
                      w="full"
                      alt="appStore"
                    />
                  </VStack>

                  <VStack>
                    <CustomImage
                      src={"/assets/images/appDownload/Timable_AOS.png"}
                      w="full"
                    />
                    <CustomImage
                      src={"/assets/images/appDownload/googlePlay.svg"}
                      w="full"
                      alt="googlePlay"
                    />
                  </VStack>
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DownloadAppModal;
