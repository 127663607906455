import { Box } from "@chakra-ui/react";

const AppBarWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      px={[1, 1, 0]}
      display={{ base: "flex", lg: "none" }}
    >
      {children}
    </Box>
  );
};

export default AppBarWrapper;
