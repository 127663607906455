import {
  Button,
  Icon,
  Popover,
  PopoverHeader,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Avatar,
  Flex,
  Box,
  Text,
} from "@chakra-ui/react";
import { FC, useEffect, useCallback } from "react";
import { useLocaleNavigate, useTranslation } from "~/libs/i18n";
import { TbSpeakerphone } from "react-icons/tb";
import GetNotificationsQuery from "~/api/query/GetNotificationsQuery";
import { useGlobalContext } from "~/context/global";
import { useLazyQuery } from "@apollo/client";
import { userAvatar } from "~/utils/member";
import { ModelTypes } from "~/zeus";
import { useMemberContext } from "~/context/member";
import dayjs from "dayjs";
import { Notification_Meta_RelationTo } from "~/zeus";

const Notification: FC = () => {
  const t = useTranslation();
  const localeNavigate = useLocaleNavigate();
  const { apolloClient } = useGlobalContext();
  const { user, memberModalDisclosure } = useMemberContext();

  const [
    fetchNotifications,
    {
      data: { GetNotifications: Notifications } = {
        GetNotifications: { docs: [], totalDocs: 0, totalPage: 0 },
      },
      loading,
    },
  ] = useLazyQuery(GetNotificationsQuery, {
    client: apolloClient,
    variables: {
      where: {
        AND: [
          {
            createdAt: {
              greater_than_equal: dayjs().startOf("month").toISOString(),
            },
          },
          {
            deletedByUser: {
              equals: false,
            }
          },
        ]
      },
      limit: 4,
      sort: "-createdAt",
    },
  });

  const [
    fetchOldestNotifications,
    {
      data: { GetNotifications: OldestNotifications } = {
        GetNotifications: { docs: [], totalDocs: 0, totalPage: 0 },
      },
    },
  ] = useLazyQuery(GetNotificationsQuery, {
    client: apolloClient,
    variables: {
      where: {
        AND: [
          {
            createdAt: {
              less_than_equal: dayjs().startOf("month").toISOString(),
            },
          },
          {
            deletedByUser: {
              equals: false,
            }
          },
        ]
      },
      limit: 4,
      sort: "-createdAt",
    },
  });

  useEffect(() => {
    if(!user) return;
    fetchOldestNotifications();
    fetchNotifications();
  }, [user]);

  const handleNotificationClick = (notification: any) => {
    if (!notification) return;
    if (
      notification?.meta?.relationTo !== Notification_Meta_RelationTo.events
    ) {
      return;
    }

    localeNavigate(
      `/event/${notification?.meta?.value?.id}/${notification?.meta?.value?.name}`
    );
  };

  const RenderNotificationContent: FC<{
    notification: ModelTypes["Notification"];
  }> = useCallback(
    ({ notification }) => {
      const user = notification?.sender as ModelTypes["User"];
      const createdAt = notification?.createdAt as string;

      return (
        <Flex
          direction={"row"}
          key={notification?.id}
          gap={4}
          p={3}
          cursor={"pointer"}
          _hover={{
            bgColor: "brand.soft-yellow",
          }}
          onClick={() => handleNotificationClick(notification)}
        >
          <Flex direction={"row"}>
            <Box
              boxSize={3}
              bg={!notification?.readed ? "brand.timable-yellow" : "#FFF"}
              borderRadius="full"
              alignSelf="flex-start"
            />
            <Avatar size="md" src={userAvatar(user)} />
          </Flex>

          <Flex direction={"column"} flex={1}>
            <Text fontSize={"xs"}>{user?.name}</Text>
            <Text fontSize={"sm"} whiteSpace={"break-spaces"} noOfLines={2}>
              {notification?.message}
            </Text>
            <Text fontSize={"xs"}>{dayjs(createdAt)?.fromNow()}</Text>
          </Flex>
        </Flex>
      );
    },
    [Notifications, OldestNotifications]
  );

  return (
    <Popover placement="bottom-end">
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              size="sm"
              variant="link"
              leftIcon={<Icon as={TbSpeakerphone} />}
              onClick={() => {
                !user && memberModalDisclosure.onOpen();
              }}
            >
              {t("notification")}
            </Button>
          </PopoverTrigger>
          {user && (<PopoverContent>
            <PopoverHeader>
              <Flex justifyContent={"space-between"}>
                <Text size="sm" fontWeight={700}>
                  {t("notification")}
                </Text>
                <Button
                  size="sm"
                  variant="link"
                  color={"brand.link-blue"}
                  onClick={() => {
                    localeNavigate(`/user/notifications`);
                    onClose();
                  }}
                >
                  {t("viewMore")}
                </Button>
              </Flex>
            </PopoverHeader>
            <PopoverBody px={0}>
              <Flex direction={"column"} gap={2}>
                <>
                  <Text fontSize="xs" px={3}>
                    {t("notifications.thisMonth")}
                  </Text>
                  {Notifications?.docs?.map((item) => {
                    return (
                      <RenderNotificationContent
                        notification={item as ModelTypes["Notification"]}
                        key={item?.id}
                      />
                    );
                  })}
                </>

                <>
                  <Text fontSize="xs" px={3}>
                    {t("notifications.oldest")}
                  </Text>
                  {OldestNotifications?.docs?.map((item) => {
                    return (
                      <RenderNotificationContent
                        notification={item as ModelTypes["Notification"]}
                        key={item?.id}
                      />
                    );
                  })}
                </>
              </Flex>
            </PopoverBody>
          </PopoverContent>)}
        </>
      )}
    </Popover>
  );
};

export default Notification;
