export const setItems = (item: String) => {
  let timableSearch: any;

  if (!!localStorage.getItem("timableSearch")) {
    timableSearch = JSON.parse(localStorage.getItem("timableSearch") as string);
  } else {
    timableSearch = [];
  }

  if(!timableSearch?.includes(item)){
    timableSearch = timableSearch?.concat(item);

    if(timableSearch.length > 5) {
      timableSearch.shift();
    }
  }

  localStorage.setItem(
    "timableSearch",
    JSON.stringify(timableSearch)
  );
};


export const getItems = () => {
    let timableSearch: any;
  
    if (!!localStorage.getItem("timableSearch")) {
      timableSearch = JSON.parse(localStorage.getItem("timableSearch") as string);
    } else {
      timableSearch = [];
    }

    timableSearch = timableSearch?.map((item: any, key: number) => {
        return {
            id: `${item}-${key}`,
            name: item
        }
    })
    
    return timableSearch.reverse();
  };
  