import type { FC } from "react";
import { Box, Button, HStack, Image, Spacer } from "@chakra-ui/react";
import {
  useLocaleNavigate,
  useTranslation,
  useRegion,
  useLocale,
} from "~/libs/i18n";
import { useLocation, Link } from "@remix-run/react";
import Section from "./Section";
import GlobalSearch from "./search/GlobalSearch";
import MENU from "~/enums/menu";
import CustomImage from "~/components/CustomImage";

const Header: FC = () => {
  const t = useTranslation();
  const navigate = useLocaleNavigate();
  const region = useRegion();
  const locale = useLocale();
  const { pathname } = useLocation();
  const parts = pathname.split("/");
  const basePath = parts[3];

  return (
    <Box
      display={{ base: "none", lg: "flex" }}
      w="full"
      h={24}
      borderBottom={1}
      borderBottomColor="gray.100"
      borderStyle="solid"
      alignItems={"center"}
    >
      <Section py={0}>
        <HStack w="full" spacing={4} alignItems="center">
          <Link to={`/${region}/${locale}`}>
            <CustomImage
              src="/assets/images/header/timable.svg"
              minW={40}
              h={"auto"}
              alt="logo"
              cursor={"pointer"}
            />
          </Link>

          <GlobalSearch />
          <Spacer />
          <HStack spacing={8} alignItems="center">
            {MENU.map(({ key, value, url }) => {
              return (
                <Button
                  onClick={() => {
                    navigate(url, { replace: true });
                  }}
                  key={key}
                  aria-label={t("key").toString()}
                  size="sm"
                  variant="link"
                  color={{
                    base: basePath === value ? "brand.timable-yellow" : "",
                  }}
                >
                  {t(key)}
                </Button>
              );
            })}
          </HStack>
        </HStack>
      </Section>
    </Box>
  );
};

export default Header;
