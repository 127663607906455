import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Avatar,
  Popover,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import { useParams, useLocation, useNavigate } from "@remix-run/react";
import { FC, useCallback } from "react";
import {
  useLocaleNavigate,
  useTranslation,
  changePathLocale,
  changePathRegion,
  AvailableLocaleType,
  AvailableRegionType,
} from "~/libs/i18n";
import {
  AiOutlineInstagram,
  AiFillFacebook,
  AiFillYoutube,
} from "react-icons/ai";
import { BiBookmark, BiMobile } from "react-icons/bi";
import { useMemberContext } from "~/context/member";
import Section from "~/components/Section";
import DownloadAppModal from "~/components/modal/DownloadAppModal";
import Notification from "~/components/navigation/Notification";

const TopNav: FC = () => {
  const t = useTranslation();
  const { locale, region } = useParams();
  const navigate = useLocaleNavigate();
  const langNavigate = useNavigate();
  const currentLocation = useLocation();
  const downloadDisclosure = useDisclosure();
  const { user, memberModalDisclosure } = useMemberContext();

  const UserButton: FC = useCallback(() => {
    const { user, isOrganiser } = useMemberContext();
    const avatarIcon =
      user?.member?.avatar?.url ?? (user?.organiser?.logo?.url as string);

    if (user) {
      return (
        <Popover placement="bottom-end">
          <PopoverTrigger>
            <IconButton
              onClick={() =>
                navigate(isOrganiser ? "/user/organiser" : "/user")
              }
              variant="ghost"
              size="sm"
              icon={<Avatar size="sm" src={avatarIcon} />}
              aria-label="login"
              rounded="full"
            ></IconButton>
          </PopoverTrigger>
        </Popover>
      );
    }

    return (
      <>
        <Button
          onClick={() => navigate(`/user/login`)}
          aria-label="login"
          size="sm"
          px={4}
          rounded="full"
        >
          {t("login")}
        </Button>
      </>
    );
  }, [user, locale]);

  const RegionSelector: FC = useCallback(() => {
    return (
      <Menu>
        <MenuButton
          as={Button}
          size="sm"
          variant="link"
          rightIcon={<ChevronDownIcon />}
        >
         {t(region as string)}
        </MenuButton>
        <MenuList>
          {[
            { value: "hk", label: "hk" },
            { value: "mo", label: "mo" },
          ].map(({ value, label }) => {
            return (
              <MenuItem
                key={value}
                onClick={() => {
                  const path = changePathRegion(
                    currentLocation?.pathname,
                    value as AvailableRegionType
                  );
                  langNavigate(path);
                }}
              >
                {t(value)}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    );
  }, [region, locale]);

  const LocaleSelector: FC = useCallback(() => {
    const { locale } = useParams();
    return (
      <Menu>
        <MenuButton
          as={Button}
          size="sm"
          variant="link"
          rightIcon={<ChevronDownIcon />}
        >
          {t(locale as string)}
        </MenuButton>
        <MenuList>
          {[
            { value: "zh", label: "繁" },
            { value: "cn", label: "簡" },
            { value: "en", label: "英" },
          ].map(({ value, label }) => {
            return (
              <MenuItem
                key={value}
                onClick={() => {
                  const path = changePathLocale(
                    currentLocation?.pathname,
                    value as AvailableLocaleType
                  );
                  langNavigate(path);
                }}
              >
                {t(value)}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    );
  }, [region, locale, currentLocation.pathname]);

  return (
    <Box>
      <Box
        display={{ base: "none", lg: "flex" }}
        w="full"
        borderBottom={1}
        borderBottomColor="gray.100"
        borderStyle="solid"
      >
        <Section>
          <HStack py={1} w="full" alignItems="center">
            <HStack spacing={0} mx={-2}>
              <IconButton
                aria-label="facebook"
                variant="link"
                color="#000"
                fontSize={["xl"]}
                icon={<Icon as={AiFillFacebook} />}
                onClick={()=>window?.open("https://www.facebook.com/timable.hk")}
              />
              <IconButton
                aria-label="youtube"
                variant="link"
                color="#000"
                fontSize={["xl"]}
                icon={<Icon as={AiFillYoutube} />}
                onClick={()=>window?.open("https://www.youtube.com/@timablechannel")}
              />
              <IconButton
                aria-label="instagram"
                variant="link"
                color="#000"
                fontSize={["xl"]}
                icon={<Icon as={AiOutlineInstagram} />}
                onClick={()=>window?.open("https://www.instagram.com/timable")}
              />
            </HStack>
            <Box flex="1" />
            <HStack spacing={[4, 6]}>
              <Button
                aria-label="download"
                size="sm"
                variant="link"
                color="#000"
                leftIcon={<Icon as={BiMobile} />}
                onClick={() => downloadDisclosure.onOpen()}
              >
                {t("download")}
              </Button>

              <RegionSelector />

              <LocaleSelector />

              <Button
                onClick={() => {
                  !!user
                    ? navigate("/user/bookmark")
                    : memberModalDisclosure.onOpen();
                }}
                aria-label="bookmark"
                size="sm"
                variant="link"
                leftIcon={<Icon as={BiBookmark} />}
              >
                {t("myBookmark")}
              </Button>

              <Notification />

              <UserButton />
            </HStack>
          </HStack>
        </Section>
      </Box>

      <DownloadAppModal downloadDisclosure={downloadDisclosure} />
    </Box>
  );
};

export default TopNav;
