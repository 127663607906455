import type { FC } from "react";
import { useEffect } from "react";
import {
  BiBookContent,
  BiBookmark,
  BiCalendarEvent,
  BiCalendarHeart,
  BiCalendarStar,
  BiHome,
  BiLocationPlus,
  BiLogOut,
  BiNews,
  BiNotification,
  BiUser,
} from "react-icons/bi";
import { CiTrophy } from "react-icons/ci";
import { IoLanguage } from "react-icons/io5";
import { PiTrainRegional } from "react-icons/pi";

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";

import { useLocation, useMatches, useNavigate } from "@remix-run/react";

import { useGlobalContext } from "~/context/global";
import { useMemberContext } from "~/context/member";
import type { AvailableLocaleType, AvailableRegionType } from "~/libs/i18n";
import {
  changePathLocale,
  changePathRegion,
  useLocale,
  useLocaleNavigate,
  useRegion,
  useTranslation,
} from "~/libs/i18n";
import { removeFloatingAd } from "~/utils/hotmob";

interface NavigationProps {
  key: string;
  label: string;
  icon?: any;
  path?: string;
  children?: NavigationProps[];
  onClick?: () => void;
}

const UserSection: FC = () => {
  const t = useTranslation();
  const navigate = useLocaleNavigate();
  const { user } = useMemberContext();
  const isLoggedIn = user ? true : false;

  const avatarIcon =
    user?.member?.avatar?.url ?? (user?.organiser?.logo?.url as string);
  return (
    <HStack
      px={6}
      py={6}
      spacing={4}
      bg={`url(/assets/images/header/drawer-header-bg.svg)`}
      onClick={() =>
        isLoggedIn ? navigate("/user/setting/profile") : navigate("/user/login")
      }
      mt={`0 !important`}
      className="userSection"
    >
      <Avatar boxShadow="md" size="md" src={avatarIcon ?? ""} />
      <VStack spacing={0} flex={1} alignItems="flex-start">
        <Text fontWeight="bold">{user?.name ?? t(`member.guest`)}</Text>
        <Text fontSize="sm" color="gray.700">
          {user?.roles ? t(`member.${user?.roles}`) : t(`member.login`)}
        </Text>
      </VStack>
    </HStack>
  );
};

const MenuDrawer: FC = () => {
  const {
    mobileDrawerDisclosure: { onClose, isOpen },
  } = useGlobalContext();
  const { user, logout, isSSOLogin } = useMemberContext();
  const t = useTranslation();
  const navigate = useLocaleNavigate();

  const locale = useLocale();
  const region = useRegion();

  const langNavigate = useNavigate();
  const currentLocation = useLocation();
  const matches = useMatches();
  const isLoggedIn = user ? true : false;

  useEffect(() => {
    onClose();
  }, [matches, onClose]);

  const onItemClick = (path?: string) => {
    if (path) {
      if (currentLocation.pathname !== `/${region}/${locale}${path}`) {
        removeFloatingAd();
        navigate(path);
        onClose();
      } else {
        onClose();
      }
    }
  };

  const handleLogout = () => {
    logout();
    onClose();
  };

  const navigation = [
    {
      key: "homapage",
      label: t("menu.main"),
      icon: BiHome,
      path: "/",
    },
    ...(isLoggedIn
      ? [
          {
            key: "bookmark",
            label: t("member.myBookmark"),
            icon: BiBookmark,
            path: "/user/bookmark/event",
          },
          {
            key: "notifications",
            label: t("member.notifications"),
            icon: BiNotification,
            path: "/user/notifications",
          },
          {
            key: "account",
            label: t("member.accountSetting"),
            icon: BiUser,
            children: [
              {
                key: "profile",
                label: t("member.personalInformation"),
                icon: BiCalendarEvent,
                path: "/user/setting/profile",
              },
              !isSSOLogin && {
                key: "password",
                label: t("member.passwordManagement"),
                icon: BiNews,
                path: "/user/setting/password",
              },
              {
                key: "delete",
                label: t("member.deleteAccount"),
                icon: BiNews,
                path: "/user/setting/delete",
              },
            ],
          },
        ]
      : []),
    {
      key: "featured",
      label: t("menu.featured"),
      icon: BiCalendarStar,
      children: [
        {
          key: "editorChoice",
          label: t("menu.editorChoice"),
          icon: BiBookContent,
          path: "/editor-choice",
        },
        {
          key: "latestEvent",
          label: t("menu.latest"),
          icon: BiCalendarEvent,
          path: "/latest-event",
        },
        {
          key: "event",
          label: t("menu.recommended"),
          icon: BiCalendarHeart,
          path: "/event",
        },
      ],
    },
    {
      key: "ranking",
      label: t("menu.ranking"),
      icon: CiTrophy,
      path: `/location-ranking`,
    },
    {
      key: "blog",
      label: t("menu.blog"),
      icon: BiNews,
      path: `/post`,
    },
    {
      key: "locations",
      label: t("locations"),
      icon: BiLocationPlus,
      path: `/location`,
    },
    ...(isLoggedIn
      ? [
          {
            key: "logout",
            label: t("logout"),
            icon: BiLogOut,
            path: "/",
            onClick: handleLogout,
          },
        ]
      : []),
  ];

  return (
    <Drawer
      isOpen={isOpen}
      placement="left"
      autoFocus={false}
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent pos={"relative"}>
        <DrawerBody p={0} as={VStack} alignItems="stretch">
          <DrawerCloseButton />
          <UserSection />
          <Accordion px={4} allowToggle>
            {navigation?.map(
              ({
                key,
                label,
                icon,
                children,
                path,
                onClick,
              }: NavigationProps & any) => {
                const hasChildren = children && children?.length > 0;
                return (
                  <AccordionItem key={key} border={0}>
                    <HStack
                      onClick={() => (onClick ? onClick() : onItemClick(path))}
                      px={4}
                      py={4}
                      as={AccordionButton}
                      spacing={4}
                    >
                      <Icon as={icon} fontSize={["xl"]} />
                      <Text flex={1} align="left">
                        {label}
                      </Text>
                      {hasChildren && <AccordionIcon />}
                    </HStack>
                    {hasChildren && (
                      <AccordionPanel>
                        {children &&
                          children?.map(({ key, label, icon, path }: any) => {
                            return (
                              <HStack
                                pl={4}
                                key={key}
                                pr={4}
                                py={2}
                                as={AccordionButton}
                                spacing={6}
                                onClick={() =>
                                  onClick ? onClick() : onItemClick(path)
                                }
                              >
                                <Icon as={icon} fontSize={["xl"]} />
                                <Text>{label}</Text>
                              </HStack>
                            );
                          })}
                      </AccordionPanel>
                    )}
                  </AccordionItem>
                );
              }
            )}

            <AccordionItem border={0}>
              <HStack px={4} py={4} as={AccordionButton} spacing={4}>
                <Icon as={PiTrainRegional} fontSize={["xl"]} />
                <Text flex={1} align="left">
                  {t(region as string)}
                </Text>
                <AccordionIcon />
              </HStack>
              <AccordionPanel>
                {[
                  { value: "hk", label: "hk" },
                  { value: "mo", label: "mo" },
                ]?.map(({ value, label }: any) => {
                  return (
                    <HStack
                      key={value}
                      pl={4}
                      pr={4}
                      py={2}
                      as={AccordionButton}
                      spacing={6}
                      onClick={() => {
                        const path = changePathRegion(
                          currentLocation?.pathname,
                          value as AvailableRegionType
                        );
                        langNavigate(path);
                      }}
                    >
                      <Text>{t(label)}</Text>
                    </HStack>
                  );
                })}
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem border={0}>
              <HStack px={4} py={4} as={AccordionButton} spacing={4}>
                <Icon as={IoLanguage} fontSize={["xl"]} />
                <Text flex={1} align="left">
                  {t(locale as string)}
                </Text>
                <AccordionIcon />
              </HStack>
              <AccordionPanel>
                {[
                  { value: "zh", label: "繁" },
                  { value: "cn", label: "簡" },
                  { value: "en", label: "英" },
                ]?.map(({ value, label }: any) => {
                  return (
                    <HStack
                      key={value}
                      pl={4}
                      pr={4}
                      py={2}
                      as={AccordionButton}
                      spacing={6}
                      onClick={() => {
                        const path = changePathLocale(
                          currentLocation?.pathname,
                          value as AvailableLocaleType
                        );
                        langNavigate(path);
                      }}
                    >
                      <Text>{label}</Text>
                    </HStack>
                  );
                })}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default MenuDrawer;
