import { Capacitor } from "@capacitor/core";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { Box } from "@chakra-ui/react";

import AppBar from ".";

import { debounce } from "~/utils/commons";

const AppBarView: FC = () => {
  const [height, setHeight] = useState("env(safe-area-inset-top)");
  const { pathname } = useLocation();
  const [bgColor, setBgColor] = useState("#fff");
  const { id } = useParams();
  const setViewWrapperTop = useCallback(() => {
    const paddingTop =
      document.documentElement.scrollTop > 0 || document.body.scrollTop > 0
        ? "env(safe-area-inset-top)"
        : "0px";
    document.documentElement.style.setProperty(
      "--scroll-padding-top",
      paddingTop
    );
    setHeight(paddingTop);
  }, []);

  const throttledSetViewWrapperTop = useCallback(
    debounce(setViewWrapperTop, 10),
    [setViewWrapperTop]
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", throttledSetViewWrapperTop);

      return () => {
        window.removeEventListener("scroll", throttledSetViewWrapperTop);
      };
    }
  }, [throttledSetViewWrapperTop]);

  useEffect(() => {
    setBgColor(id ? "transparent" : "#fff");
  }, [pathname, id]);

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 99,
        bgColor: bgColor,
      }}
      borderBottom={"1px solid"}
      borderColor={id ? "transparent" : "brand.light-grey"}
    >
      <Box height={height}></Box>
      <AppBar />
    </Box>
  );
};

export default AppBarView;
