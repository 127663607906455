export default [
  {
    key: "menu.latest",
    value: "latest-event",
    url: "/latest-event",
  },
  { key: "menu.recommended", 
    value: "hot-event",
    url: "/hot-event" 
    },
  {
    key: "menu.editorChoice",
    value: "editor-choice",
    url: "/editor-choice",
  },
  {
    key: "menu.page",
    value: "organiser-page",
    url: "/organiser-page",
  },
  { key: "menu.blog", value: "post", url: "/post" },
];