import type { FC } from "react";
import { useEffect, useState } from "react";

import { ChevronRightIcon } from "@chakra-ui/icons";
import { Flex, Spacer, StyleProps, VStack } from "@chakra-ui/react";

import Date from "~/components/card/fragments/Date";
import Location from "~/components/card/fragments/Location";
import Thumbnail from "~/components/card/fragments/Thumbnail";
import Title from "~/components/card/fragments/Title";
import { LinkWrap } from "~/components/fragments/UI";
import { useLocale, useRegion, useTranslation } from "~/libs/i18n";
import { generateURL } from "~/utils/commons";
import { getDatetimeDisplay, getTimeSectionByDateRange } from "~/utils/event";
import type { ModelTypes } from "~/zeus";

const EventCardInGlobalSearchList: FC<
  StyleProps & {
    event: ModelTypes["Event"];
  }
> = ({ event, ...flexProps }) => {
  const t = useTranslation();
  const section = getTimeSectionByDateRange(event) ?? event?.sections?.[0];
  const [displayDate, setDisplayDate] = useState<string | null>(null);
  const locale = useLocale();
  const region = useRegion();
  useEffect(() => {
    const display = getDatetimeDisplay({
      t,
      locale,
      section: section as ModelTypes["Event_Sections"],
    });

    setDisplayDate(display);
  }, [section]);

  const detailURL = generateURL(`event`, event?.id, event?.name);

  return (
    <LinkWrap to={`/${region}/${locale}${detailURL}`}>
      <Flex
        key={event.id}
        overflow="hidden"
        rounded="md"
        gap={0}
        cursor={"pointer"}
        alignItems="stretch"
        position={"relative"}
        _hover={{
          bg: "gray.50",
          boxShadow: "sm",
          cursor: "pointer",
          transitionProperty: "all",
          transitionDuration: "0.3s",
        }}
        p={4}
        bgColor={"brand.light-grey"}
        {...flexProps}
      >
        <Flex direction={"row"} alignItems={"center"} gap={4} w={"100%"}>
          <Thumbnail
            w={[24]}
            h={[24]}
            url={detailURL}
            media={event?.thumbnail}
            src={event?.thumbnail?.url as string}
            rounded={"md"}
            overflow={"hidden"}
          />

          <VStack alignItems="stretch" flex={1} spacing={1}>
            <Title
              noOfLines={1}
              title={event.name as string}
              fontSize={["md"]}
            />
            {displayDate && <Date date={displayDate} noOfLines={1} />}
            {section?.address && (
              <Location location={section?.address} noOfLines={1} />
            )}
          </VStack>
          <ChevronRightIcon boxSize={6} />
        </Flex>
      </Flex>
    </LinkWrap>
  );
};

export default EventCardInGlobalSearchList;
