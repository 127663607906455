import type { FC } from "react";
import { AiOutlineMenu, AiOutlineSearch } from "react-icons/ai";

import { HStack, Icon, IconButton } from "@chakra-ui/react";

import CustomImage from "../CustomImage";
import AppBarWrapper from "./AppBarWrapper";

import { useGlobalContext } from "~/context/global";
import { useLocaleNavigate } from "~/libs/i18n";

const AppBarLogo = () => {
  const navigate = useLocaleNavigate();
  return (
    <CustomImage
      src="/assets/images/logo_horizontal.png"
      h={"auto"}
      w={24}
      onClick={() => navigate("/")}
    />
  );
};

const MainBar: FC = () => {
  const {
    mobileDrawerDisclosure: { onOpen },
    mobileSearchDrawerDisclosure: { onOpen: onSearchOpen },
  } = useGlobalContext();

  return (
    <AppBarWrapper>
      <HStack w="full" py={4} alignItems="center">
        <IconButton
          variant="link"
          aria-label="menu"
          size="lg"
          icon={<Icon as={AiOutlineMenu} />}
          onClick={onOpen}
        />
        <HStack flex={1} justifyContent="center">
          <AppBarLogo />
        </HStack>
        <IconButton
          variant="link"
          aria-label="menu"
          size="lg"
          icon={<Icon as={AiOutlineSearch} />}
          onClick={() => onSearchOpen()}
        />
      </HStack>
    </AppBarWrapper>
  );
};

export default MainBar;
