import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverContent,
  PopoverBody,
  usePopper,
  Heading,
  Flex,
  VStack,
  Image,
  CloseButton,
} from "@chakra-ui/react";
import { FC, useEffect, useRef, useState } from "react";
import { useLocaleNavigate, useTranslation } from "~/libs/i18n";
import { useLocation, useParams, useSearchParams } from "@remix-run/react";
import { AiOutlineSearch } from "react-icons/ai";
import EventCardInGlobalSearchList from "~/components/card/EventCardInGlobalSearchList";
import GetFilterEventsQuery from "~/api/query/GetFilterEventsQuery";
import { useGlobalContext } from "~/context/global";
import { useLazyQuery } from "@apollo/client";
import TagsQuery from "~/api/query/TagsQuery";
import { Tags as TagsList } from "~/components/event/Tags";
import { Event_region_Input, LocaleInputType, ModelTypes } from "~/zeus";
import { setItems, getItems } from "~/utils/localeStorage";
import { SkeletonCard } from "~/components/fragments/UI";
import dayjs from "dayjs";
import { generateId } from "~/utils/commons";

const GlobalSearch: FC = () => {
  const t = useTranslation();
  const { apolloClient, addEventViewCount } = useGlobalContext();
  const location = useLocation();
  const [inputValue, setInputValue] = useState<String>("");
  const inputValueRef = useRef(inputValue);
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef<HTMLInputElement | null>(null);
  const popper = usePopper();
  const [sp] = useSearchParams();
  const navigate = useLocaleNavigate();
  const [displayKeywords, setDisplayKeywords] = useState<String[]>([]);

  const [fetchEvents, { data, loading }] = useLazyQuery(GetFilterEventsQuery, {
    client: apolloClient,
  });

  //@ts-ignore
  const [getTags, { data: { Tags } = {}, loading: tagsLoading }] = useLazyQuery(
    TagsQuery,
    {
      client: apolloClient,
    }
  );
  const params = useParams()
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Update input value in state and inputValueRef
    const newValue = e.target.value;

    setInputValue(newValue);
    inputValueRef.current = newValue;

    newValue !== "" && setIsOpen(true);
  };

  const handleClosePopover = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setIsOpen(false);
    if (sp.get("search")) {
      setInputValue(sp.get("search") as string);
    } else {
      setInputValue("");
    }
  }, [location]);

  useEffect(() => {
    const where: ModelTypes["Event_where"] = {};

    where.AND = [];

    where.AND.push({
      sections__endDatetime: {
        // now
        greater_than_equal: dayjs().tz("Asia/Hong_Kong").toISOString(),
      },
      sections__toThisDay: {
        // now
        greater_than_equal: dayjs().tz("Asia/Hong_Kong").toISOString(),
      },
    });

    if (isOpen) {
      fetchEvents({
        variables: {
          locale: params.locale as LocaleInputType,
          page:1,
          where: {
            ...where,
            region: {
              equals: params.region as Event_region_Input,
            },
          },
          limit: 3,
          sort: "-_viewsCountByMonth",
        },
      });
      getTags({
        variables: {
          where: {},
          limit: 12,
        },
      });
      setDisplayKeywords(getItems());
    }
  }, [isOpen]);

  const handleTrendEventMiscClick = (eventId: string) => {
    if (!eventId) return;
    addEventViewCount(generateId(eventId.toString()), "miscInternalClick");
  };

  useEffect(() => {
    if (data?.GetFilterEvents?.docs) {
      const eventIds = data?.GetFilterEvents?.docs
        ?.filter((event) => event)
        ?.map((evt) => evt?.id && generateId(evt.id));
      eventIds?.length > 0 &&
        addEventViewCount(eventIds.toString(), "miscImpression");
    }
  }, [data]);

  return (
    <Box pos={"relative"} w={"100%"}>
      <InputGroup size="sm" pos={"relative"} zIndex={11}>
        <InputLeftElement
          pointerEvents="none"
          children={<Icon as={AiOutlineSearch} w={5} h={5} />}
          h="full"
          pl={4}
        />
        <Input
          type="text"
          variant="filled"
          rounded="full"
          w={"full"}
          maxW={[400]}
          bgColor="#F7F7F7"
          placeholder={t("form.searchEvents") as string}
          h={10}
          pl={10}
          onChange={handleInputChange}
          ref={triggerRef}
          value={inputValue as string}
          onFocus={() => {
            setIsOpen(true);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              const search = inputValueRef.current as string;
              setItems(search);
              navigate(`/event?search=${encodeURIComponent(search)}&page=1`);
              setIsOpen(false);
            }
          }}
        />
      </InputGroup>
      <Popover
        {...popper}
        isOpen={isOpen}
        placement="bottom"
        closeOnBlur={true}
        autoFocus={false}
        onClose={handleClosePopover}
      >
        <PopoverContent
          minW={{ base: "100%", lg: "max-content" }}
          top={12}
          onMouseLeave={() => setIsOpen(false)}
        >
          <PopoverBody p={4}>
            <VStack
              spacing={8}
              alignItems={"flex-start"}
              maxW={620}
              pos={"relative"}
            >
              <CloseButton
                onClick={() => setIsOpen(false)}
                right={0}
                pos={"absolute"}
              />
              <Flex direction={"column"} gap={2} w={"90%"}>
                <Heading fontSize={["sm", "md"]}>
                  {t("filters.recentlySearched")}
                </Heading>

                <Box zIndex={2} position={"relative"}>
                  <TagsList
                    tags={displayKeywords ?? []}
                    func={navigate}
                    cursor={"pointer"}
                  />
                </Box>
              </Flex>

              <Flex direction={"column"} gap={2} w={"90%"}>
                <Heading fontSize={["sm", "md"]}>
                  {t("filters.hotKeyword")}
                </Heading>
                {tagsLoading && <SkeletonCard item={2} />}
                <TagsList
                  tags={Tags?.docs ?? []}
                  func={navigate}
                  cursor={"pointer"}
                />
              </Flex>
              <Flex direction={"column"} gap={2} w={{ base: "full", lg: 620 }}>
                <Heading fontSize={["sm", "md"]}>
                  {t("event.trendEvents")}
                </Heading>
                {loading && <SkeletonCard />}
                <Flex
                  direction={"column"}
                  gap={2}
                  // maxH={"500px"}
                  overflow={"auto"}
                >
                  {data?.GetFilterEvents?.docs?.map((event) => {
                    return (
                      <Box
                        onClick={() => handleTrendEventMiscClick(event?.id!)}
                      >
                        <EventCardInGlobalSearchList
                          event={event as ModelTypes["Event"]}
                          key={event.id}
                        />
                      </Box>
                    );
                  })}
                </Flex>
              </Flex>
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default GlobalSearch;
